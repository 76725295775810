<div class="callout callout-warning m-0 py-3 update-log-block mt-2" [ngClass]="{'callout-warning':log.action=='edit','callout-success':log.action=='add'}">
    <div class="text-truncate font-weight-bold user_name flex">
        <div class="avatar">
            <img src="{{log.user.image_url}}" class="img-avatar" alt="{{log.user.email}}">
        </div>
        <div class="username">
            {{log.user.name}}
        </div>
    </div>
    <div style="font-weight: bold;font-size: 13px;">
        <strong *ngIf="log.action == 'add'">作成しました</strong>
        <strong *ngIf="log.action == 'edit'">以下の項目が更新されました</strong>
    </div>
    <div *ngIf="log.action==='edit'">
        <ng-container *ngFor="let diff of log.diff_a">
            <div *ngIf="diff['form_type']=='image'"><b>{{diff['label']}}</b>&nbsp;画像変更</div>
            <ng-container *ngIf="diff['deleted'] && diff['deleted'].length>0">
                <div>
                    <b class="text-danger">{{diff['label']}}削除:</b> {{diff['deleted']}}
                </div>
            </ng-container>
            <ng-container *ngIf="diff['added'] && diff['added'].length>0">
                <div>
                    <b class="text-primary">{{diff['label']}}追加:</b> {{diff['added']}}
                </div>
            </ng-container>
            <ng-container *ngIf="diff['from']">
                <div *ngIf="diff['form_type']!='image'">
                    <b>{{diff['label']}}</b><br>
                    &nbsp;{{getDataStr(diff['from'], diff)}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.63281 7.8828C8.12109 7.39452 8.12109 6.60155 7.63281 6.11327L2.63281 1.11327C2.27344 0.753893 1.73828 0.648424 1.26953 0.843736C0.800781 1.03905 0.496094 1.49217 0.496094 1.99999V12C0.496094 12.5039 0.800781 12.9609 1.26953 13.1562C1.73828 13.3515 2.27344 13.2422 2.63281 12.8867L7.63281 7.88671V7.8828Z" fill="#5C5C5C"/>
                    </svg>

                    &nbsp;&nbsp;&nbsp;
                    {{getDataStr(diff['to'], diff)}}
                </div>
            </ng-container>
        </ng-container>

    </div>
    <div *ngIf="comment" class="comment">
        {{comment.content}}
    </div>
    <small class="text pull-right">&nbsp; {{log.created}}</small><br>
    <!-- <small class="text"><i class="icon-user"></i>&nbsp; {{log.user.name}}</small> -->
</div>
