<ul class="nav-menus" *ngIf="show_dashboard">
    <ng-container *ngFor="let dashboard of show_dashboard">
      <li class="nav-menus__item nav-tag" *ngIf="!show_only_directory">
        <a 
          class="nav-menus__link -nowrap" 
          [class.active]="isActive(dashboard.name)" 
          (click)="onTabChange(dashboard)"
        >
          <span>{{ dashboard.name }}</span>
        </a>
      </li>
    </ng-container>
  </ul>
  
