import { Directive, HostListener, ElementRef, Renderer2, Input } from '@angular/core';

@Directive({
  selector: '[appDecimalSeparator]',
})
export class DecimalSeparatorDirective  {
  @Input() decimalPlaces: number = 2;
  @Input() separateNumber: boolean = true;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    this.formatAndSetValue(value);
  }
  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string): void {
    this.formatAndSetValue(value);
  }

  private formatAndSetValue(value: string): void {
    const inputElement = this.el.nativeElement;
    const rawValue = inputElement.value.replace(/,/g, ''); // Remove commas
    const oldCursorPos = inputElement.selectionStart;
    const oldCommaCount = (inputElement.value.match(/,/g) || []).length;

    let formattedValue = rawValue;
    if (this.separateNumber) {
      formattedValue = this.addCommas(rawValue);
    }
    const newCommaCount = (formattedValue.match(/,/g) || []).length;
    const commaDifference = newCommaCount - oldCommaCount;
    let newCursorPos = oldCursorPos + commaDifference;
    newCursorPos = Math.max(0, Math.min(formattedValue.length, newCursorPos));    
    this.renderer.setProperty(inputElement, 'value', formattedValue);
    setTimeout(() => inputElement.setSelectionRange(newCursorPos, newCursorPos));
  }

  private addCommas(value: string): string {
    if (!value) return '';

    const [integerPart, decimalPart] = value.split('.');
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return decimalPart !== undefined
      ? `${formattedInteger}.${decimalPart.slice(0, this.decimalPlaces)}`
      : formattedInteger;
  }
}
