import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AsideService {
  private asideClosedSource = new Subject<void>();
  asideClosed$ = this.asideClosedSource.asObservable();

  // Call this method to notify when aside is closed
  closeAside() {
    this.asideClosedSource.next();
  }
}
