<div *ngIf="isShow" class="flow-container">
    <div class="shadow  mb-2 row ml-1 mr-1">
        <span class="content">
            コネクトの設定が完了しましたら、「コネクトを保存」ボタンをクリックしてください。もし、保存しない場合は「キャンセル」をクリックしてください。
        </span>
        <button class="btn btn-default btn-cancel" data-style="expand-right" [ladda]='sending' type="button">
            <span style="color: rgba(32, 32, 32, 0.4);">
                キャンセル
            </span>
        </button>
        <button class="btn btn-primary btn-save ml-2" data-style="expand-right">
           <span class="connect-save"> コネクトを保存 </span> 
        </button>
    </div>
    <div class="card">
        <div class="card-block">
            
            <div class="col-md-12">
                コネクト名  <i class="fa-solid fa-star-of-life required"></i>
                <input type="text" class="form-control" [(ngModel)]="name" placeholder="フロー名">
            </div>
            <div class="col-md-12 pl-4 mt-2">
                <select-field [disabled]="this.id" [use_condition]="false" [table_name]="table" [is_select_field]="false" (valueChange)="tableChanged($event)"></select-field>
            </div>

            <div class="col-md-6 pt-4 ">
                <ng-container *ngIf="!tableNotSelected()">
                    <!--    <button class="btn btn-danger" (click)="resetLocalStorage()">ローカルストレージをリセット</button>-->
                    <button class="btn btn-sm btn-default mr-1" (click)="openJsonModal()">JSON表示</button>
                    <!-- PIgeonAI button -->
                    <button class="btn btn-sm btn-success mr-1" (click)="openPigeonAiModal()">PigeonAI</button>
                    <!--            <button class="btn btn-success mr-1" (click)="arrangeBlocks()">整列</button>-->
                    <!-- もとに戻すボタン -->
                    <button class="btn btn-sm btn-warning mr-1" (click)="undoChanges()" [disabled]="!triggerHistoryService.canUndo()">
                        <i class="fa fa-undo" aria-hidden="true"></i>
                    </button>
                    <!-- やり直しボタン -->
                    <button class="btn btn-sm btn-info mr-1" (click)="redoChanges()" [disabled]="!triggerHistoryService.canRedo()">
                        <i class="fa fa-repeat" aria-hidden="true"></i>
                    </button>
                    <!-- 有効 switch -->
                    <label class="switch switch-lg switch-text switch-pill switch-primary ml-2">
                        <input type="checkbox" class="switch-input" [(ngModel)]="enabled">
                        <span class="switch-label" data-on="有効" data-off="無効"></span>
                        <span class="switch-handle"></span>
                    </label>
                </ng-container>
                <!-- 保存ボタン -->
                <button *ngIf="table" class="btn btn-sm btn-primary ml-2" (click)="saveFlowCheck()" [ladda]="loading">
                    <i class="fa fa-floppy-o" aria-hidden="true"></i>&nbsp;
                    <ng-container *ngIf="tableNotSelected()">作成</ng-container>
                    <ng-container *ngIf="!tableNotSelected()">保存</ng-container>
                </button>
            </div>
        </div>

    </div>
    
    <div *ngIf="tableNotSelected(); else TableSelected">
        まずテーブルを選択し、作成を押してください。
    </div>
    <ng-template #TableSelected>
        
            <div class="card">
                <div class="card-block">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="row pull-right">
                                
                                <button class="btn btn-primary add-flow-btn mb-2" (click)="addBlock()">
                                    <svg class="flow-btn-svg" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.50977 2.125C9.50977 1.43359 8.95117 0.875 8.25977 0.875C7.56836 0.875 7.00977 1.43359 7.00977 2.125V7.75H1.38477C0.693359 7.75 0.134766 8.30859 0.134766 9C0.134766 9.69141 0.693359 10.25 1.38477 10.25H7.00977V15.875C7.00977 16.5664 7.56836 17.125 8.25977 17.125C8.95117 17.125 9.50977 16.5664 9.50977 15.875V10.25H15.1348C15.8262 10.25 16.3848 9.69141 16.3848 9C16.3848 8.30859 15.8262 7.75 15.1348 7.75H9.50977V2.125Z" fill="white"/>
                                    </svg>
                                     &nbsp;&nbsp;   
                                    <span class="flow-add-content">
                                        ブロックを追加
                                    </span>
                                    
                                </button>
                               
                            </div>
                            
                            <div class="mt-conntect">
                                
                                <ng-container *ngFor="let block of blocks; let i=index">
                                    <div *ngIf="i > 0" [style]="getArrowStyle(i)">
                                        <svg width="8" height="46" viewBox="0 0 8 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.65133 45.3536C3.84659 45.5488 4.16317 45.5488 4.35844 45.3536L7.54042 42.1716C7.73568 41.9763 7.73568 41.6597 7.54042 41.4645C7.34515 41.2692 7.02857 41.2692 6.83331 41.4645L4.00488 44.2929L1.17646 41.4645C0.981194 41.2692 0.664611 41.2692 0.469349 41.4645C0.274087 41.6597 0.274087 41.9763 0.469349 42.1716L3.65133 45.3536ZM3.50488 0L3.50488 45H4.50488L4.50488 0L3.50488 0Z" fill="#5C5C5C"/>
                                        </svg>
                                            
                                    </div>
                                    <div class="card flow-card"  [style]="getBoxStyle(i)" >
                                        <p class="text-bold">
                                            <!-- タイプを表すアイコンを表示 -->
                                            <i *ngIf="block.type === 'TRIGGER'" class="fa fa-play-circle-o" aria-hidden="true"></i>
                                            <i *ngIf="block.type === 'EMAIL_NOTIFICATION'" class="fa fa-envelope-o" aria-hidden="true"></i>
                                            <i *ngIf="block.type === 'COPY'" class="fa fa-files-o" aria-hidden="true"></i>
                                            <i *ngIf="block.type === 'COPY_OTHER_TABLE'" class="fa fa-files-o" aria-hidden="true"></i>
                            
                                            <i *ngIf="block.type === 'FILTER'" class="fa fa-filter" aria-hidden="true"></i>
                                            <i *ngIf="block.type === 'SLEEP'" class="fa fa-clock-o" aria-hidden="true"></i>
                                            <i *ngIf="block.type === 'CREATE_DATA'" class="fa fa-plus" aria-hidden="true"></i>
                                            <i *ngIf="block.type === 'UPDATE_DATA'" class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                            <i *ngIf="block.type === 'DELETE_DATA'" class="fa fa-trash-o" aria-hidden="true"></i>
                                            <i *ngIf="block.type === 'CONDITION'" class="fa fa-question-circle-o" aria-hidden="true"></i>
                                            <i *ngIf="block.type === 'SLACK_NOTIFICATION'" class="fa fa-slack" aria-hidden="true"></i>
                                            {{ block.name }}
                            
                                        </p>
                                        <!-- トリガブロック専用のUI -->
                                        <app-trigger-block [block]="block" *ngIf="isTriggerBlock(block)"></app-trigger-block>
                            
                                        <!-- メール送信ブロック専用のUI -->
                                        <app-email-noti-block [block]="block" *ngIf="isEmailNotificationBlock(block)"></app-email-noti-block>
    
                                        <!-- コピー専用のUI -->
                                        <app-copy-block [block]="block" [table_info]="table_info" *ngIf="block.type == Block.TYPE_COPY"></app-copy-block>
                            
                                        <app-copy-other-table-block [block]="block" [table_info]="table_info" *ngIf="block.type == Block.TYPE_COPY_OTHER_TABLE"></app-copy-other-table-block>
                                
                                        <!-- フィルタ専用のUI -->
                                        <app-filter-block [block]="block" [table_info]="table_info" *ngIf="block.type == Block.TYPE_FILTER"></app-filter-block>
                                
                                        <!-- スリープ専用のUI -->
                                        <app-sleep-block [block]="block" [table_info]="table_info" *ngIf="block.type == Block.TYPE_SLEEP"></app-sleep-block>
                                
                                        <!-- データ作成専用のUI -->
                                        <app-create-block [block]="block" [table_info]="table_info" *ngIf="block.type == Block.TYPE_CREATE"></app-create-block>
                                
                                        <!-- データ更新専用のUI -->
                                        <app-update-block [block]="block" [table_info]="table_info" *ngIf="block.type == Block.TYPE_UPDATE"></app-update-block>
                                
                                        <!-- データ削除専用のUI -->
                                        <app-delete-block [block]="block" [table_info]="table_info" *ngIf="block.type == Block.TYPE_DELETE"></app-delete-block>
                                
                                        <!-- 条件ブロック専用のUI -->
                                        <app-condition-block [block]="block" *ngIf="isConditionBlock(block)"></app-condition-block>
                                
                                        <!-- Slack通知ブロック専用のUI -->
                                        <app-slack-noti-block [block]="block" *ngIf="isSlackNotificationBlock(block)"></app-slack-noti-block>

                                        <!-- 通常のブロックUI -->
                                        <div class="icon-container">

                                            <i *ngIf="block.type === Block.TYPE_TRIGGER" class="fa-solid fa-gear fa-icon edit-icon pull-right" aria-hidden="true" (click)="setEdittingBlock(block) && triggerModal.show()"></i>
                                            <i *ngIf="block.type === Block.TYPE_EMAIL_NOTIFICATION" class="fa-solid fa-gear fa-icon edit-icon" aria-hidden="true"
                                                (click)="setEdittingBlock(block) && this.emailNotificationBlockModal.show()"></i>
                                            <i *ngIf="block.type === Block.TYPE_SLACK_NOTIFICATION" class="fa-solid fa-gear fa-icon edit-icon" aria-hidden="true" (click)="setEdittingBlock(block) && this.slackNotiModal.show()"></i>
                                            <i *ngIf="block.type === Block.TYPE_COPY" class="fa-solid fa-gear fa-icon edit-icon pull-right" aria-hidden="true" (click)="setEdittingBlock(block) && this.copyBlockModal.show()"></i>
                                            <i *ngIf="block.type === Block.TYPE_COPY_OTHER_TABLE" class="fa-solid fa-gear fa-icon edit-icon pull-right" aria-hidden="true"
                                                (click)="setEdittingBlock(block) && this.copyOtherTableBlockModal.show()"></i>
                                            <i *ngIf="block.type === Block.TYPE_FILTER" class="fa-solid fa-gear fa-icon edit-icon pull-right" aria-hidden="true" (click)="setEdittingBlock(block) && this.filterBlockModal.show()"></i>
                                            <i *ngIf="block.type === Block.TYPE_SLEEP" class="fa-solid fa-gear fa-icon edit-icon pull-right" aria-hidden="true" (click)="setEdittingBlock(block) && this.sleepBlockModal.show()"></i>
                                            <i *ngIf="block.type === Block.TYPE_CREATE" class="fa-solid fa-gear fa-icon edit-icon pull-right" aria-hidden="true" (click)="setEdittingBlock(block) && this.createBlockModal.show()"></i>
                                            <i *ngIf="block.type === Block.TYPE_UPDATE" class="fa-solid fa-gear fa-icon edit-icon pull-right" aria-hidden="true" (click)="setEdittingBlock(block) && this.updateBlockModal.show()"></i>
                                            <i *ngIf="block.type === Block.TYPE_DELETE" class="fa-solid fa-gear fa-icon edit-icon" aria-hidden="true" (click)="setEdittingBlock(block) && this.deleteBlockModal.show()"></i>

                                            <!-- preview icon -->
                                            <i class="fa fa-eye preview-icon pull-right" aria-hidden="true" (click)="setEdittingBlock(block) && this.previewModal.show()"></i>
                                

                                            <!-- 削除アイコン -->
                                            <i *ngIf="block.type!='TRIGGER'" class="fa-solid fa-trash delete-icon text-danger " aria-hidden="true" (click)="deleteConfirm(block.id)"></i>

                                            <div class="dragger" cdkDragHandle>
                                                <i class="fa-solid fa-grip-vertical"></i>
                                            </div>
                                           
                                        </div>
                            
                            
                                        <!-- trueNext、falseNext、またはnextに応じてエンドポイントを表示 -->
                                        <div *ngIf="block.trueNext || block.falseNext" class="endpoint true-endpoint" [attr.data-block-id]="block.id" [attr.data-endpoint]="block.id + '-true'"></div>
                                        <div *ngIf="block.falseNext" class="endpoint false-endpoint" [attr.data-block-id]="block.id" [attr.data-endpoint]="block.id + '-false'"></div>
                                        <div *ngIf="block.next" class="endpoint next-endpoint" [attr.data-block-id]="block.id" [attr.data-endpoint]="block.id + '-next'"></div>

                                    </div>

                                </ng-container>
                            </div>
                           
                           
                        </div>
                        
                        <div class="col-md-3">
                            <div style="display: flex; position: sticky; top: 0;">
    
                                <div class="toggle-drag-field-button"></div>
                                <div (scroll)="onScrollSidebar($event)" class="toggle-drag-field-list" style="display:block;width: 100%;overflow-y: scroll;max-height: 100vh;">

                                    <div cdkDropList [cdkDropListData]="blockTypeOptions" class="drag-options" >
                                        <div *ngFor="let option of blockTypeOptions" cdkDrag [cdkDragData]="option" 
                                             (cdkDragStarted)="onDragStarted(option)" 
                                             
                                             class="field-drag" >
                                          <button class="btn btn-lg btn-block btn-light-gray" type="button">
                                            <span class="type-label">{{ option.label }}</span>
                                          </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row pull-right">
                        <button type="button" class="btn btn-secondary btn-ladda mr-2" (click)="addModal.hide()">キャンセル</button>
                        <button type="button" class="btn btn-primary btn-ladda mr-r" (click)="saveFlowCheck()">コネクトを保存</button>
                    </div>

                </div>
            </div>
      
            
    </ng-template>


</div>

<!-- add block modal -->
<div bsModal [config]="{backdrop: 'static', keyboard: false}" #addModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-primary" x role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    ブロック追加
                </h4>
            </div>
            <div class="modal-body">
                <select class="form-control" [(ngModel)]="selectedBlockType">
                    <!-- blockTypeOptions配列を使用 -->
                    <option *ngFor="let option of blockTypeOptions" [value]="option.value">{{ option.label }}</option>
                </select>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-ladda" (click)="addModal.hide()">キャンセル</button>
                <button type="button" class="btn btn-success btn-ladda" (click)="addBlockSave()">追加</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div>

<!-- JSON editable display modal -->
<div bsModal [config]="{backdrop: 'static', keyboard: false}" #jsonModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div *ngIf="jsonModal.isShown" class="modal-dialog modal-primary" x role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    JSON
                </h4>
            </div>
            <div class="modal-body">
                <textarea id="jsonText" style="height:500px;" class="form-control" rows="10" [(ngModel)]="edittingBlocks">{{ edittingBlocks }}</textarea>
            </div>
            <div class="modal-footer">
                <!-- copy -->
                <button type="button" class="btn btn-primary btn-ladda" (click)="copyJson()">コピー</button>

                <button type="button" class="btn btn-secondary btn-ladda" (click)="jsonModal.hide()">閉じる</button>
                <button type="button" class="btn btn-success btn-ladda" (click)="reflectJson()">反映</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<!-- PigeonAI request modal -->
<div bsModal [config]="{backdrop: 'static', keyboard: false}" #pigeonAiModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true" [config]="{ backdrop: 'static', keyboard: false }">
    <div *ngIf="pigeonAiModal.isShown" class="modal-dialog modal-primary" x role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    PigeonAI
                </h4>
            </div>
            <div class="modal-body">
                どのようなフローを作成したいか、AIにリクエストするテキストを入力してください。以下は例<br>
                <div style="    background: #eee; border: 1px solid #ccc; padding: 15px;">
                    毎日朝９時に実行してください。<br>
                    ID>4かつ、顧客名が「山田太郎」のデータを取得してください。<br>
                    そのデータに対して、受注日を2023/01/01にしてください。
                </div>


                <textarea id="jsonText" style="height:500px;" class="form-control" rows="10" placeholder="毎日朝９時に実行してください。..." [(ngModel)]="requestGptText">{{ requestGptText }}</textarea>
            </div>
            <div class="modal-footer">

                <button [disabled]="gptLoading" type="button" class="btn btn-secondary" (click)="pigeonAiModal.hide()">閉じる</button>
                <button [ladda]="gptLoading" type="button" class="btn btn-success btn-ladda" (click)="requestPigeonAi()">反映</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->


<!-- preview list data modal -->
<div bsModal [config]="{backdrop: 'static', keyboard: false}" #previewModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div *ngIf="previewModal.isShown" class="modal-dialog modal-primary modal-large" x role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    プレビュー
                </h4>
            </div>
            <div class="modal-body">
                <pfc-list [table]="table" [_customFilter]="EdittingBlockfilter" [embedMode]="true" [viewDataMode]="true"></pfc-list>


            </div>
            <div class="modal-footer">

                <button type="button" class="btn btn-secondary btn-ladda" (click)="previewModal.hide()">閉じる</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div>

<!-- トリガブロック専用モーダル -->
<app-trigger-edit-modal #triggerModal [srcBlock]="edittingBlock" (onChange)="onChangeEdittingBlock($event,'TRIGGER')"></app-trigger-edit-modal>

<app-email-noti-edit-modal #emailNotificationBlockModal [srcBlock]="edittingBlock" [toasterService]="toasterService"
                           (onChange)="onChangeEdittingBlock($event,'EMAIL_NOTIFICATION')"></app-email-noti-edit-modal>
<app-copy-block-edit-modal #copyBlockModal [table_info]="edittingBlockTableInfo" [srcBlock]="edittingBlock" (onChange)="onChangeEdittingBlock($event,'COPY')"></app-copy-block-edit-modal>

<app-copy-other-table-block-edit-modal #copyOtherTableBlockModal [table_info]="edittingBlockTableInfo" [srcBlock]="edittingBlock"
                                       (onChange)="onChangeEdittingBlock($event,'COPY_OTHER_TABLE')"></app-copy-other-table-block-edit-modal>

<app-filter-block-edit-modal #filterBlockModal [table_info]="edittingBlockTableInfo" [srcBlock]="edittingBlock" (onChange)="onChangeEdittingBlock($event,'FILTER')"></app-filter-block-edit-modal>

<app-sleep-edit-modal #sleepBlockModal [table_info]="edittingBlockTableInfo" [srcBlock]="edittingBlock" (onChange)="onChangeEdittingBlock($event,'SLEEP')"></app-sleep-edit-modal>

<app-create-edit-modal #createBlockModal [table_info]="edittingBlockTableInfo" [srcBlock]="edittingBlock" (onChange)="onChangeEdittingBlock($event,'CREATE_DATA')"></app-create-edit-modal>

<app-update-edit-modal #updateBlockModal [table_info]="edittingBlockTableInfo" [srcBlock]="edittingBlock" (onChange)="onChangeEdittingBlock($event,'UPDATE_DATA')"></app-update-edit-modal>

<app-delete-edit-modal #deleteBlockModal [table_info]="edittingBlockTableInfo" [srcBlock]="edittingBlock" (onChange)="onChangeEdittingBlock($event,'DELETE_DATA')"></app-delete-edit-modal>

<app-slack-noti-edit-modal #slackNotiModal [table_info]="edittingBlockTableInfo" [srcBlock]="edittingBlock" (onChange)="onChangeEdittingBlock($event,'SLACK_NOTIFICATION')"></app-slack-noti-edit-modal>


<confirm-modal #blockDeleteConfirmModal [submit_button_text]="'はい'" [text]="'削除してよろしいですか？'" [type]="'warning'" (onOk)="deleteBlock()"></confirm-modal>
<confirm-modal #saveConfirmModal [submit_button_text]="'はい'" [text]="'RPAが有効化になっています。保存してよろしいですか？ \nデータの更新・削除などは元に戻せないため、十分に注意してONにしてください。'"
               [type]="'warning'" (onCancel)="enabled=false" (onOk)="saveFlow()"></confirm-modal>

