import {Component, Input, OnInit, AfterViewInit,OnChanges,SimpleChanges } from '@angular/core';
import {SharedService} from '../../services/shared';
import {MenuNode} from '../../class/MenuNode';
import {Dashboard} from '../../class/Dashboard';
import { DashboardComponent } from '../../dashboard/dashboard.component';

@Component({
    selector: 'dashboard-nav-menus',
    templateUrl: './dashboard-nav-menus.component.html',
    styleUrls: ['./nav-menus.component.scss']
})
export class DashboardNavMenusComponent implements OnInit,OnChanges, AfterViewInit {
    @Input() menu_root: MenuNode;
    @Input() menu_root_dashboard: MenuNode;
    @Input() show_only_directory: boolean;
    @Input() show_root: boolean;
    @Input() show_dashboard: Array<Dashboard> = [];
    activeTab: string;
    
    constructor(public _share: SharedService) {}

    ngOnInit() {
      this._share.initializeDashboardData();
      this._share.getDashboards().subscribe((data: Dashboard[]) => {
        this.show_dashboard = data;
        if (this.show_dashboard.length > 0) {
           this.setDefaultActiveTab();
        }
      });
      this._share.activeDashboardTab$.subscribe((tabName) => {
          this.activeTab = tabName;
      });
    }
  
    ngOnChanges(changes: SimpleChanges) {
        if (changes['show_dashboard'] && changes['show_dashboard'].currentValue) {
            this.setDefaultActiveTab();
        }
        
    }

    ngAfterViewInit() {
        this.setDefaultActiveTab();
    }
   

    setDefaultActiveTab() {
      if (this.show_dashboard.length > 0) {
        this.activeTab = this.show_dashboard[0].name;
        this._share.breadcrumbs = [
          { 'link': '/admin/dashboard', 'name': 'ダッシュボード' },
          { 'name': this.activeTab }
        ];
        this._share.setDashboardActiveTab(this.activeTab); // Inform subscribers about the active tab
      }

    }

    setActiveTab(tabName: string) {
      this.activeTab = tabName;
  }

    onTabChange(dashboard: any) {
     
        this._share.sendTabData(dashboard)
        this.activeTab = dashboard.name;
    }

    isActive(tabName): boolean {
        
        return this.activeTab === tabName;
    }
  
}



